import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Nirvana = () => {
  const headingbanner = {
    title: `Nirvana`,
    content: `A Financial Solutions Platform`,
  };

  const data = {
    images: ["nirvana-casestudy.webp", "nirvana-casestudy-2.webp"],
    firstAlt: "UI UX Orders List Screen",
    firstTitle: "Orders List",
    secondAlt: "UI UX Screen To Show The Order Status",
    secondTitle: "Order Status",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["FinTech"],
      },
      {
        title: "What we did",
        text: ["Design Audit", "UI UX Designing", "React Development"],
      },
      {
        title: "Platform",
        text: ["Desktop Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Upon embarking on this project, we identified several issues with the existing platform. The placement of buttons and features appeared to be haphazard, as if they had been added without a clear plan or structure. `,
          `Furthermore, the call-to-action buttons were not appropriately aligned with their respective functions or sections. The user experience was compromised by the need for excessive clicking to perform specific actions, which resulted in a lack of efficiency. `,
          `From a UI perspective, the design seemed outdated compared to industry standards, and there was a lack of clear visual cues to guide users in performing actions. The blotter section contained unnecessary complexities, adding further complications to the user experience. `,
        ],
      },
      {
        title: "Solution",
        content: [
          `To address the identified challenges, our proposed solutions include:`,
        ],
        list: [
          `Thoughtful restructuring of buttons and features for a logical and intuitive layout.`,
          `Realignment of call-to-action buttons with their respective parent elements.`,
          `Streamlined workflow to minimize unnecessary clicks and enhance task efficiency.`,
          `Implementation of modern design practices with clear visual cues for user guidance.`,
          `Simplification of the blotter section to reduce complexity and improve data management.`,
        ],
        content2: [
          `Our goal is to deliver an integrated, multi-asset class workflow platform that surpasses industry standards in usability, efficiency, and visual appeal.`,
        ],
      },
    ],
    image: ["nirvana-problem.webp"],
  };

  const projectData = {
    title: "Blotter",
    para: [
      `Blotter is designed to functions as a comprehensive Execution Management System (EMS), providing real-time insights and control over your financial transactions. `,
      `With its intuitive user interface, Blotter presents essential trade details, including transaction time, symbol, side (Buy, Sell, or Sell Short), status, quantity, and the chosen broker. `,
    ],
    content: {
      image: ["nirvana-projectone.webp"],
      text: `By seamlessly integrating these components, Blotter empowers you to monitor trade execution, track order status, and analyze transaction history with ease. Stay on top of your trades and make informed decisions with Blotter, the ultimate tool for efficient trade management.`,
    },
  };

  const projectData2 = {
    title: `Trading Ticket`,
    para: [
      `The Trading Ticket covers all aspects of the investment implementation workflow and is delivered via a convenient, affordable cloud-based model. `,
      `The solutions provides FIX connectivity to hundreds of executing brokers, Algorithms, and EMS's. `,
      `Using the single integrated application users can execute orders at a multitude of destinations with the brokers of their choice.`,
    ],
    content: {
      image: ["nirvana-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: "Ready To Serve In Industry",
    para: [
      `We have reached to a decision regarding the final outcome of the project. `,
      `Taking into account the needs of traders who spend long hours looking at computer screens, we have determined that a light theme can strain the eyes. Consequently, we have opted for a dark theme that enhances readability and reduces eye strain for the users.`,
      `With this new UI design, our creation stands out from the competition and captures users' attention, truly embodying the concept of Nirvana.`,
    ],
    content: {
      image: [
        "nirvana-projectthree.webp",
        "nirvana-projectthree-2.webp",
        "nirvana-projectthree-3.webp",
        "nirvana-projectthree-4.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `Nirvana offers an integrated, multi-asset class workflow platform.`,
        `However, the existing platform faced challenges with`,
      ],
      list: [
        `Workflow Efficiency`,
        `Unnecessary Complexities`,
        `Incorrect Placement of Major Actions`,
        `UI Design`,
        `Order Executions Complexity`,
      ],
      para2: [
        `To address these issues, proposed solutions involve restructuring`,
      ],
      list2: [
        `Actions`,
        `Realigning Call-to-Action Elements`,
        `Streamlining Workflows`,
        `Adopting Modern Design Practices`,
        `Simplifying The Blotter Section`,
      ],
      para3: [
        `These improvements aim to enhance usability, efficiency, and visual appeal. The introduction of Blotter as a comprehensive EMS enables real-time insights, transaction control, and seamless trade monitoring and analysis.`,
        `The revamped platform aims to provide a superior user experience and facilitate efficient trade management.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={20} />
      </div>
    </Layout>
  );
};

export default Nirvana;

export const Head = () => (
  <Seo title="Nirvana- Financial Solutions Platform Case Study | Octet" description="A fintech platform delivering integrated, multi-asset workflow solutions with our services on Design Audit, UI UX Designing and ReactJs Development." />
)